import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme.js';
import ScrollFromTop from './ScrollFromTop';
import bookGif from './Images/Book.gif'
// Lazy-loaded components
const Home = lazy(() => import('./pages/Home/Home.jsx'));
const About = lazy(() => import('./pages/About/About.jsx'));
const Courses = lazy(() => import('./pages/Courses/Courses.jsx'));
const ContactUs = lazy(() => import('./pages/RequestCall/RequestCall.jsx'));
const NotFound = lazy(() => import('./NotFound.jsx')); // 404 component

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ChakraProvider theme={theme}>
    <Router>
      <ScrollFromTop />
      <Suspense fallback={
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          textAlign: 'center',
          fontSize: '1.5em',
          fontWeight: '800',
          bgColor:"#000",
          color: '#525fe1'
        }}>
          <img src={bookGif} alt="Loading..." style={{ width: '60px', marginBottom: '10px' }} />
          <div style={{ fontSize: '1.5em', fontWeight: '800' ,color: '#525fe1' }}>Loading.....</div>
</div>

      }>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="*" element={<NotFound />} /> {/* Fallback route */}
        </Routes>
      </Suspense>
    </Router>
  </ChakraProvider>
);
